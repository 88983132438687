<template>
  <section id="banner" class="page">
    <banner-image-overlay :fullscreen="true"></banner-image-overlay>
    <div class="main-container">
      <h2 class="title">Register</h2>
      <div class="d-flex justify-content-center">
        <article>
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <input name="email" v-model="email"
                    class="form-control" placeholder="Email"
                    type="email" required maxlength="64"
                    autocomplete="username">
            </div>
            <div class="form-group">
              <input id="password" name="password" v-model="password"
                    class="form-control" placeholder="Password"
                    type="password" required minlength="8" maxlength="20"
                    autocomplete="new-password">
            </div>
            <div class="form-group">
              <input id="confirmPassword" name="confirmPassword" v-model="confirmPassword"
                    class="form-control" placeholder="Confirm password"
                    type="password" required autocomplete="off"
                    minlength="8" maxlength="20">
            </div>
            <div class="alert alert-danger align-left" v-show="error">{{ error }}</div>
            <div class="alert alert-success align-left" v-show="success">{{ success }}</div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <button type="submit" class="button special">Register</button>
                </div>
              </div>
              <div class="col-6 align-right">
                <router-link :to="{ name: 'Login' }"
                class="small login">Already have an account?</router-link>
            </div>
            </div>
          </form>
          <!-- <hr> -->
          <!-- <div class="social-media">
            <div class="row">
              <div class="col">
                <h3 class="title">Or Via Social Media</h3>
              </div>
            </div>
            <div class="row buttons">
              <div class="col-lg">
                <a href="" class="btn btn-block btn-outline-primary btn-linkedin">
                  <i class="fab fa-linkedin"></i> &nbsp;  LinkedIn
                </a>
              </div>
              <div class="col-lg">
                <a href="" class="btn btn-block btn-outline-primary btn-twitter">
                  <i class="fab fa-twitter"></i> &nbsp;  Twitter
                </a>
              </div>
              <div class="col-lg">
                <a href="" class="btn btn-block btn-outline-primary btn-facebook">
                  <i class="fab fa-facebook-f"></i> &nbsp;  Facebook
                </a>
              </div>
            </div>
          </div> -->
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';
import UserApi from '../../api/User';

export default {
  name: 'Signup',
  components: {
    BannerImageOverlay,
  },
  data: function data() {
    return {
      email: null,
      password: null,
      confirmPassword: null,
      error: '',
      success: '',
    };
  },
  methods: {
    onSubmit: function onSubmit() {
      this.error = '';
      this.success = '';

      if (this.password !== this.confirmPassword) {
        const confirmPasswordElem = document.getElementById('confirmPassword');
        confirmPasswordElem.setCustomValidity('Passwords don\'t match');
        confirmPasswordElem.reportValidity();
        confirmPasswordElem.oninput = this.onInput;
        document.getElementById('password').oninput = this.onInput;
        return;
      }

      UserApi
        .signup(this.email, this.password)
        .then((response) => {
          this.success = response.data.message;
          this.email = '';
          this.password = '';
          this.confirmPassword = '';
          window.fbq('track', 'CompleteRegistration', {
            content_name: 'signup',
          });
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to register, please try later');
        });
    },
    onInput() {
      if (this.password === this.confirmPassword) {
        const confirmPasswordElem = document.getElementById('confirmPassword');
        confirmPasswordElem.setCustomValidity('');
        confirmPasswordElem.reportValidity();
        confirmPasswordElem.oninput = null;
        document.getElementById('password').oninput = null;
      }
    },
  },
};
</script>

<style scoped>
  a.login {
    position: relative;
    top: -8px;
    color: white;
    font-style: italic;
    text-decoration: none;
  }

  a.login:hover {
    color: #f32853;
  }

  .social-media .title {
    color: white;
  }

  strong, b {
    color: #B6B6B6;
  }

  article {
    width: 500px;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: #38AAF0;
  }

  button[type=submit] {
    float: left;
  }

  i.fab {
    margin-right: 7px;
  }

  .btn-outline-primary {
    font-size: 17px;
  }

  .btn-outline-primary.btn-linkedin {
    border-color: #0077B5 !important;
    color: white;
    background-color: #0077B5 !important;
  }

  .btn-outline-primary.btn-twitter {
    border-color: #1DA1F2 !important;
    color: white;
    background-color: #1DA1F2 !important;
  }

  .btn-outline-primary.btn-facebook {
    border-color: #4267B2 !important;
    color: white;
    background-color: #4267B2 !important;
  }

  .fa-facebook-f:before {
    content: "\f39e";
  }

  #banner {
    height: 100%;
    padding-top: 22vh;
  }

  @media screen and (max-width: 991px) {
    .social-media .buttons .col-lg .btn-outline-primary {
      margin-top: 15px;
    }

    .social-media .buttons .col-lg:first-child .btn-outline-primary {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 736px) {
    #banner {
      padding-left: 0;
      padding-right: 0;
    }

    .main-container {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  @media screen and (max-height: 639px) {
    #banner {
      height: fit-content;
    }
  }

  @media screen and (max-width: 480px) {
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button,
    .button {
        padding: 0 2.2em;
    }
  }

  @media screen and (max-width: 414px) {
    #banner {
      background-position-x: -65px;
    }
  }
</style>
